@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  background: var(--bg);
  transition: all 3ms ease;
  color: var(--black);
  scroll-behavior: smooth;
}
