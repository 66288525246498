.cFooterWrapper {
  width: 100%;
  margin-top: 5rem;
}

.cFooterWrapper > hr {
  background: #fff;
  border: none;
  height: 0.8px;
  margin-bottom: 1rem;
  width: 100%;
}
.cFooter {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 1rem;
}
.logo {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}
.logo > img {
  width: 2.5rem;
  height: 2.5rem;
}
.logo > span {
  font-weight: 600;
  font-size: 1rem;
}
.block {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 12rem;
}
.detail {
  display: flex;
  flex-direction: column;
  width: inherit;
  font-size: 0.7rem;
  gap: 1rem;
  font-style: italic;
}
.detail > span:nth-of-type(1) {
  font-weight: 500;
  font-style: normal;
  font-size: 1.2rem;
}
.pngLine:hover {
  cursor: pointer;
}
.pngLine {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.icon {
  width: 25px;
}
.copyRight {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  font-size: 0.65rem;
  
  margin: 2rem 0px 1rem;
}
@media screen and (max-width: 768px) {
  .cFooter {
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    font-size: 1.7rem;
    gap: 2rem;
  }
}

@media screen and (max-width: 640px) {
  .cFooter {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .logo {
    align-items: center;
    gap: 0.5rem;
  }
  .cFooter .pngLine {
    justify-content: center;
  }
}
